<div class="mainContainerOfferSection">
  <div class="container_data">
    <div class="containerData_schoolName">
      <span>{{ courseOfferDataToShow.school.name }}</span>
    </div>
    <div class="containerData_offerName">
      <span>{{ courseOfferDataToShow.courseOffer.name }}</span>
    </div>
    <div class="containerData_offerData">
      <div class="offerData_infoSection">
        <strong>Tipo de curso: </strong><span>{{ courseOfferDataToShow.courseOffer.type }}</span>
      </div>
      <div class="offerData_infoSection">
        <strong>Cupos: </strong><span>{{ courseOfferDataToShow.courseOffer.quotas }}</span>
      </div>
      <div class="offerData_infoSection">
        <strong>Inscritos: </strong><span>{{ courseOfferDataToShow.courseOffer.registered }}</span>
      </div>
    </div>
  </div>
  <div class="container_state">
    <!-- /* TODO: dinamic css with state data */ -->
    <span>{{ courseOfferDataToShow.courseOffer.state }}</span>
  </div>
</div>
