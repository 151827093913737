<div class="subjectAreaCourseDetailsCMainContainer">
  <button class="containerTittle" (click)="showDetails = !showDetails">
    <span class="subjectName">{{ subjectDataToShow.detalles.name }}</span>
    <div class="descriptionSection_right">
      <em class="icon-i-angle-right"></em>
    </div>
  </button>
  @if (showDetails) {
    <div class="containerDescript">
      <div class="containerDescript_line">
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Nombre de la materia</span><br>
          <span>{{ subjectDataToShow.detalles.name }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Semestres</span><br>
          <span>{{ subjectDataToShow.detalles.semestre }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Tipo de calificación</span><br>
          <span>{{ subjectDataToShow.detalles.tipoCalificacion }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Nota mínima para aprobar</span><br>
          <span>{{ subjectDataToShow.detalles.notaMinApro }}</span>
        </div>
      </div>
      <div class="containerDescript_line">
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Área académica</span><br>
          <span>{{ subjectDataToShow.detalles.areaAcademica }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Prerrequisito</span><br>
          <span>{{ subjectDataToShow.detalles.prerequisito }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Tipo</span><br>
          <span>{{ subjectDataToShow.detalles.tipo }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Créditos</span><br>
          <span>{{ subjectDataToShow.detalles.creditos }}</span>
        </div>
      </div>
      <div class="containerDescript_line">
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Hora presencial</span><br>
          <span>{{ subjectDataToShow.detalles.horaPresencial }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Hora asistida</span><br>
          <span>{{ subjectDataToShow.detalles.horaAsistida }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Hora independiente</span><br>
          <span>{{ subjectDataToShow.detalles.horaIndependiente }}</span>
        </div>
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Máximo hora cátedras diarias</span><br>
          <span>{{ subjectDataToShow.detalles.maxHoraCatDiaria }}</span>
        </div>
      </div>
      <div class="containerDescript_line">
        <div class="containerDescriptLine_simpleCol">
          <span class="tittleDetails">Porcentaje dentro del área</span><br>
          <span>{{ subjectDataToShow.detalles.porcentajeArea }}</span>
        </div>
        <div class="containerDescriptLine_thirthCol">
          <span class="tittleDetails">Descripción del área</span><br>
          <span>{{ subjectDataToShow.detalles.descripcion }}</span>
        </div>
      </div>
    </div>
  }
</div>
