import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { Course } from '@models/db/course';
import { Subject } from '@models/db/Subject';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'modal-manage-subject',
  standalone: true,
  imports: [
    /** Jhan components */
    UiInputComponent,
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    /** Angular */
    FormsModule,
  ],
  templateUrl: './manage-subject.component.html',
  styleUrl: './manage-subject.component.css'
})
export class ManageSubjectComponent {

  @Input() isNewData!: boolean;
  @Input() subjectID!: number;
  @Input() courseData!: Course;
  @Output() eventCloseModal = new EventEmitter<void>();
  @Output() eventUpdateDataAndCloseModal = new EventEmitter<void>();

  protected localSubjectData: Subject = new Subject();
  protected dataToShow: DataToShow = {
    tittle: '',
    subTittle: '',
    subTittleDesc: '',
    programa: '',
    btnText: '',
  };

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) { };

  ngOnInit(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this.dataToShow = {
      tittle: ( this.isNewData ? 'Agregar materia' : 'Editar materia' ),
      subTittle: ( this.isNewData ? 'Crear materias' : 'Editar materias' ),
      subTittleDesc: ( this.isNewData ? 'crear' : 'editar' ),
      programa: this.courseData.NameCourse!,
      btnText: ( this.isNewData ? 'Crear' : 'Actualizar' ),
    };
    if (this.isNewData) this.localSubjectData = new Subject();
    else {
      let subjectFinded = this.courseData.Subjects!.find( subject => subject.id == this.subjectID );
      if (subjectFinded) this.localSubjectData = subjectFinded;
      else {
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{ this.closeModal() });
        return;
      }
    }
    this._sweetalertService.swalClose();
  }

  protected closeModal(): void {
    this.eventCloseModal.emit();
  }

  protected updateDataAndCloseModal(): void {
    this.eventUpdateDataAndCloseModal.emit();
  }

  protected validateDataBeforeSendingReq(): void {
    console.log('validateDataBeforeSendingReq');
    console.log('localSubjectData: ', this.localSubjectData);
    let isOk = true;
    if (this.isNewData) {
      /** TODO: hacer validaciones de campos */
      if (isOk) this.createNewSubject();
      else this._sweetalertService.swalError('Error', 'Datos incompletos', ()=>{});
    } else {
      /** TODO: hacer validaciones de campos */
      /** TODO: hacer validaciones de si hay campos editados */
      if (isOk) this.editSubjectSelected();
    }
  }

  protected createNewSubject(): void {
    console.log('createNewSubject');
    let listObservable: any = {};
    this.localSubjectData.CourseID = this.courseData.id;
    // this.areaDataToCreate.forEach((element, index) => {
      const dataToUpdate = {
        path: `Subjects`,
        data: this.localSubjectData
      };
      listObservable[`NEW_AREA_00`] = this._apiService.post( dataToUpdate ).pipe( take( 1 ) );
    //   listObservable[`NEW_AREA_${index}`] = this._apiService.post( dataToUpdate ).pipe( take( 1 ) );
    // });

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin(listObservable).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewArea error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  protected editSubjectSelected(): void {
    console.log('editSubjectSelected');
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const dataToUpdate = {
      path: `Subjects/${this.subjectID}`,
      data: this.localSubjectData
    };
    this._apiService.patch( dataToUpdate ).pipe( take(1) ).subscribe({
      next: (responseUpdate) => {
        console.log('responseUpdate: ', responseUpdate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han actualizado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("editAreaSelected error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  protected confirmDeleteSubjectSelected(): void {
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      '¿Esta seguro de borrar esta materia?',
      () => { this.deleteSubjectSelected() }
    );
  }

  protected deleteSubjectSelected(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const dataToDelete = {
      path: `Subjects/${this.subjectID}`,
    };
    this._apiService.delete( dataToDelete ).pipe( take(1) ).subscribe({
      next: (responseDelete) => {
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han eliminado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("deleteAreaSelected error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

}

interface DataToShow {
  tittle: string,
  subTittle: string,
  subTittleDesc: string,
  programa: string,
  btnText: string,
}