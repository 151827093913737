/**
 * enumCourseModalTabsOptions contiene la lista de
 * los tabs de la modal de crear/editar programa
 */
export enum enumCourseModalTabsOptions {
  DETAILS,
  NOTES_CONFIG,
  AREAS,
  SUBJECTS,
  PERFORMANCES,
}
