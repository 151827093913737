export class CourseOfferInfoMidterms {
  midtermNum?: number;
  description?: string;
  percentage?: number;
  professorCanEditPercentage?: boolean;
  midTermStart?: string;
  midTermEnd?: string;
  midTermRecordStart?: string;
  midTermRecordEnd?: string;
  remedialGradeStart?: string;
  remedialGradeEnd?: string;

  constructor (data?: Partial<CourseOfferInfoMidterms>) {
    Object.assign(this, data);
  }

  /** ******* */
  /** METHODS */
  /** ******* */

}
