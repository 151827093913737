import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AcademicaArea } from '@models/db/AcademicaArea';
import { Course } from '@models/db/course';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'modal-manage-area',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './manage-area.component.html',
  styleUrl: './manage-area.component.css'
})
export class ManageAreaComponent implements OnInit {

  @Input() isNewData!: boolean;
  @Input() areaID!: number;
  @Input() courseData!: Course;
  @Output() eventCloseModal = new EventEmitter<void>();
  @Output() eventUpdateDataAndCloseModal = new EventEmitter<void>();

  protected academicAreaData: AcademicaArea | undefined = undefined;
  protected dataToShow: DataToShow = {
    tittle: '',
    subTittle: '',
    subTittleDesc: '',
    programa: '',
    btnText: '',
  };
  protected areaDataToEdit: AreaDataToEdit = {
    name: '',
    descript: '',
  };
  protected areaDataToCreate: AreaDataToEdit[] = [{
    name: '',
    descript: '',
  }];

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) { };

  ngOnInit(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this.dataToShow = {
      tittle: ( this.isNewData ? 'Agregar área' : 'Editar área' ),
      subTittle: ( this.isNewData ? 'Crear áreas' : 'Editar áreas' ),
      subTittleDesc: ( this.isNewData ? 'crear' : 'editar' ),
      programa: this.courseData.NameCourse!,
      btnText: ( this.isNewData ? 'Crear' : 'Actualizar' ),
    };
    if (this.isNewData) {
      this.areaDataToCreate = [{
        name: '',
        descript: '',
      }];
    } else {
      this.academicAreaData = this.courseData.School?.AcademicAreas?.find( area => area.id == this.areaID );
      if (!this.academicAreaData) {
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{ this.closeModal() });
        return;
      }
      this.areaDataToEdit = {
        name: this.academicAreaData.NameArea!,
        descript: this.academicAreaData.Description!,
      };
    }
    this._sweetalertService.swalClose();
  }

  protected closeModal(): void {
    this.eventCloseModal.emit();
  }

  protected updateDataAndCloseModal(): void {
    this.eventUpdateDataAndCloseModal.emit();
  }

  protected validateDataBeforeSendingReq(): void {
    let isOk = true;
    if (this.isNewData) {
      this.areaDataToCreate.forEach(newArea => {
        if (
          newArea.name.trim() == '' ||
          newArea.descript.trim() == ''
        ) isOk = false;
      });
      if (isOk) this.createNewArea();
      else this._sweetalertService.swalError('Error', 'Debe ingresar nombre y descripción', ()=>{});
    } else {
      if (
        this.areaDataToEdit.name.trim() == '' ||
        this.areaDataToEdit.descript.trim() == ''
      ) {
        isOk = false;
        this._sweetalertService.swalError('Error', 'Debe ingresar nombre y descripción', ()=>{});
      }
      if (
        this.areaDataToEdit.name.trim() == this.academicAreaData?.NameArea?.trim() &&
        this.areaDataToEdit.descript.trim() == this.academicAreaData?.Description?.trim()
      ) {
        isOk = false;
        this._sweetalertService.swalError('Error', 'No hay cambios para guardar', ()=>{});
      }
      if (isOk) this.editAreaSelected();
    }
  }

  protected createNewArea(): void {
    let listObservable: any = {};
    this.areaDataToCreate.forEach((element, index) => {
      const dataToUpdate = {
        path: `AcademicaAreas`,
        data: {
          Description: element.descript.trim(),
          NameArea: element.name.trim(),
          SchoolID: this.courseData.SchoolID,
        }
      };
      listObservable[`NEW_AREA_${index}`] = this._apiService.post( dataToUpdate ).pipe( take( 1 ) );
    });

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin(listObservable).subscribe({
      next: (responseUpdate) => {
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewArea error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  protected editAreaSelected(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const dataToUpdate = {
      path: `AcademicaAreas/${this.areaID}`,
      data: {
        Description: this.areaDataToEdit.descript.trim(),
        NameArea: this.areaDataToEdit.name.trim(),
      }
    };
    this._apiService.patch( dataToUpdate ).pipe( take(1) ).subscribe({
      next: (responseUpdate) => {
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han actualizado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("editAreaSelected error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  protected confirmDeleteAreaSelected(): void {
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      '¿Esta seguro de borrar esta área?',
      () => { this.deleteAreaSelected() }
    );
  }

  protected deleteAreaSelected(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const dataToDelete = {
      path: `AcademicaAreas/${this.areaID}`,
    };
    this._apiService.delete( dataToDelete ).pipe( take(1) ).subscribe({
      next: (responseUpdate) => {
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han eliminado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("deleteAreaSelected error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  protected addNewAreaSectionToCreate(): void {
    this.areaDataToCreate.push({ name: '', descript: '' });
  }

  protected confirmDeleteAreaSectionToDelete(index: number): void {
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      '¿Esta seguro de borrar esta área?',
      () => { this.deleteAreaSectionToCreate(index) }
    );
  }

  protected deleteAreaSectionToCreate(index: number): void {
    this.areaDataToCreate.splice(index, 1);
  }

}

interface DataToShow {
  tittle: string,
  subTittle: string,
  subTittleDesc: string,
  programa: string,
  btnText: string,
}

interface AreaDataToEdit {
  name: string,
  descript: string,
}
