<div class="modalMainContainer">
  <div class="myModal">
    <div class="modalHeader">
      <div class="modalHeader_info">
        <span class="headerMainInfo">{{ dataToShow.tittle }}</span><br>
        <span class="headerSecondaryInfo">{{ dataToShow.subTittle }} todos los detalles del programa</span><br>
      </div>
      <div class="modalHeader_close">
        <button class="btnCallToAction" (click)="closeModal()">
          <em class="icon-i-close"></em>
        </button>
      </div>
    </div>
    <div class="modalNavbar">
      <div class="tabsContainer">
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.DETAILS }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.DETAILS">Detalles del programa</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.NOTES_CONFIG }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.NOTES_CONFIG">Configuración de notas</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.AREAS }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.AREAS">Asignar áreas</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.SUBJECTS }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.SUBJECTS">Asignar materias</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.PERFORMANCES }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.PERFORMANCES">Asignar desempeños</button>
      </div>
    </div>
    <div class="modalBody">
      @switch ( MANAGE_TABS.index ) {
        @case ( MANAGE_TABS.allOptions.DETAILS ) {
          <div class="containerCourseData">

            <div class="courseProperty courseProperty__100"><!-- Select SCHOOL -->
              <ui-select variant="bordered" label="Escuela" defaultValue="0" [(ngModel)]="localCourseData.SchoolID" (onValueChange)="onSchoolSelectChange($event, false)">
                <ui-select-content>
                  <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                  @for (schoolIterated of allSchoolsListData; track schoolIterated.id) {
                    <ui-select-item [value]="schoolIterated.id!">{{ schoolIterated.NameTSchool }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>

            <div class="courseProperty courseProperty__100"><!-- Input Nombre programa -->
              <ui-input
                variant="bordered"
                label="Nombre del programa"
                placeholder="Escriba nombre del programa"
                [(ngModel)]="localCourseData.NameCourse"
              />
            </div>

            <div class="courseProperty courseProperty__50"><!-- Select tipo de categoría -->
              <ui-select variant="bordered" label="Tipo de categoría" defaultValue="0" [(ngModel)]="localCourseData_categoryID">
                <ui-select-content>
                  <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                  @for (categoryIterated of ALL_CATEGORIES_LIST; track categoryIterated.id) {
                    <ui-select-item [value]="categoryIterated.id!">{{ categoryIterated.NameCategoryCourse }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>

            <div class="courseProperty courseProperty__50"><!-- Select tipo de programa -->
              <ui-select variant="bordered" label="Tipo de programa" defaultValue="0" [(ngModel)]="localCourseData.TypeCourseID">
                <ui-select-content>
                  <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                  @for (typeCourseIterated of allTypeCoursesListData; track typeCourseIterated.id) {
                    <ui-select-item [value]="typeCourseIterated.id!">{{ typeCourseIterated.NameTypeCourse }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>
      
            <div class="courseProperty courseProperty__50"><!-- Select Estado del curso -->
              <ui-select variant="bordered" label="Estado del curso" defaultValue="true" [(ngModel)]="localCourseData.IsActive">
                <ui-select-content>
                  <ui-select-item value="true">Activo</ui-select-item>
                  <ui-select-item value="false">Inactivo</ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
      
            @if (false) {<!-- Es anual -- Luis dijo que esto ya no va -->
              <div class=""><!-- Select -->
                <ui-select variant="bordered" label="¿Es anual?" defaultValue="true" [(ngModel)]="localCourseData.IsAnnual">
                  <ui-select-content>
                    <ui-select-item value="true">Si</ui-select-item>
                    <ui-select-item value="false">No</ui-select-item>
                  </ui-select-content>
                </ui-select>
              </div>
            }
      
            <div class="courseProperty courseProperty__50"><!-- Select -->
              <ui-select variant="bordered" label="Duración" defaultValue="true" [(ngModel)]="localCourseData.typeCourseDurationID">
                <ui-select-content>
                  @for (typeCourseDurationIterated of ALL_TYPE_COURSES_DURATION_LIST; track typeCourseDurationIterated.id) {
                    <ui-select-item [value]="typeCourseDurationIterated.id!">{{ typeCourseDurationIterated.nameTypeDuration }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>
      
            <div class="courseProperty courseProperty__50"><!-- Select -->
              <ui-select variant="bordered" label="Rango del aspirante" defaultValue="true" [(ngModel)]="localCourseData.IsMilitar">
                <ui-select-content>
                  <ui-select-item value="true">Solo Militar</ui-select-item>
                  <ui-select-item value="false">Militar o civil</ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
      
            <div class="courseProperty courseProperty__50"><!-- Select -->
              <!-- TODO: Revisar "profiles" data -->
              <ui-select variant="bordered" label="Perfiles" defaultValue="0" [(ngModel)]="localCourseData.profiles">
                <ui-select-content>
                  <ui-select-item value="00" [disabled]="true">Seleccionar</ui-select-item>
                  <ui-select-item value="GR">General</ui-select-item>
                  <ui-select-item value="MG">Mayor General</ui-select-item>
                  <ui-select-item value="BG">Brigadier General</ui-select-item>
                  <ui-select-item value="CR">Coronel</ui-select-item>
                  <ui-select-item value="TC">Teniente Coronel</ui-select-item>
                  <ui-select-item value="MY">Mayor</ui-select-item>
                  <ui-select-item value="CT">Capitán</ui-select-item>
                  <ui-select-item value="TE">Teniente</ui-select-item>
                  <ui-select-item value="ST">Subteniente</ui-select-item>
                  <ui-select-item value="ALF">Alférez</ui-select-item>
                  <ui-select-item value="CD">Cadete</ui-select-item>
                  <ui-select-item value="SME">Sargento Mayor de Ejército</ui-select-item>
                  <ui-select-item value="SMC">Sargento Mayor de Comando</ui-select-item>
                  <ui-select-item value="SM">Sargento Mayor</ui-select-item>
                  <ui-select-item value="SP">Sargento Primero</ui-select-item>
                  <ui-select-item value="SV">Sargento Vice Primero</ui-select-item>
                  <ui-select-item value="SS">Sargento Segundo</ui-select-item>
                  <ui-select-item value="CP">Cabo Primero</ui-select-item>
                  <ui-select-item value="CS">Cabo Segundo</ui-select-item>
                  <ui-select-item value="C3">Cabo Tercero</ui-select-item>
                  <ui-select-item value="DG">Dragoneante</ui-select-item>
                  <ui-select-item value="AL">Alumno SubOficial</ui-select-item>
                  <ui-select-item value="SL">Soldado</ui-select-item>
                  <ui-select-item value="IMP">Infante de Marina Profesional</ui-select-item>
                  <ui-select-item value="EJC">EJERCITO NACIONAL DE COLOMBIA</ui-select-item>
                  <ui-select-item value="ARC">ARMADA DE LA REPUBLICA DE COLOMBIA</ui-select-item>
                  <ui-select-item value="FAC">FUERZA AÉREA COLOMBIANA</ui-select-item>
                  <ui-select-item value="PONAL">POLICIA NACIONAL DE COLOMBIA</ui-select-item>
                  <ui-select-item value="INPEC">INSTITUTO NACIONAL PENITENCIARIO Y CARCELARIO</ui-select-item>
                  <ui-select-item value="CTI">CUERPO TÉCNICO DE INVESTIGACION</ui-select-item>
                  <ui-select-item value="CIM">INFANTERIA DE MARINA COLOMBIA</ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>

            @if (localCourseData_categoryID == 1) {<!-- TODO: Revisar ID para prod y para celic/cedoc -->
              <div class="courseProperty courseProperty__50"><!-- Select Semestres -->
                <ui-select variant="bordered" label="Semestres" defaultValue="0" [(ngModel)]="localCourseData.DurationTotal">
                  <ui-select-content>
                    <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                    <ui-select-item value="1">1</ui-select-item>
                    <ui-select-item value="2">2</ui-select-item>
                    <ui-select-item value="3">3</ui-select-item>
                    <ui-select-item value="4">4</ui-select-item>
                    <ui-select-item value="5">5</ui-select-item>
                    <ui-select-item value="6">6</ui-select-item>
                    <ui-select-item value="7">7</ui-select-item>
                    <ui-select-item value="8">8</ui-select-item>
                    <ui-select-item value="9">9</ui-select-item>
                    <ui-select-item value="10">10</ui-select-item>
                    <ui-select-item value="11">11</ui-select-item>
                    <ui-select-item value="12">12</ui-select-item>
                  </ui-select-content>
                </ui-select>
              </div>
            }
      
            <div class="courseProperty courseProperty__50"><!-- Input -->
              <ui-input
                variant="bordered"
                label="Minutos de una hora cátedra"
                placeholder="Minutos de una hora cátedra"
                [(ngModel)]="localCourseData.TeachingHoursmin"
              />
            </div>
      
            <div class="courseProperty courseProperty__50"><!-- Select -->
              <ui-select variant="bordered" label="Tipo de calificación" defaultValue="''" [(ngModel)]="localCourseData.TypeRecord">
                <ui-select-content>
                  <ui-select-item value="''" [disabled]="true">Seleccionar</ui-select-item>
                  <ui-select-item value="'1-5'">1 a 5</ui-select-item>
                  <ui-select-item value="'1-10'">1 a 10</ui-select-item>
                  <ui-select-item value="'0-5'">0 a 5</ui-select-item>
                  <ui-select-item value="'0-10'">0 a 10</ui-select-item>
                  <ui-select-item value="'Cualitativo'">Cualitativo</ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
      
            <div class="courseProperty courseProperty__50"><!-- Input -->
              <ui-input
                variant="bordered"
                label="Nota mínima para aprobar"
                placeholder="Nota mínima para aprobar"
                [(ngModel)]="localCourseData.DesiredGrade"
              />
            </div>

            @if (localCourseData_categoryID == 2) {<!-- TODO: Revisar ID para prod y para celic/cedoc -->
              <div class="courseProperty courseProperty__50"><!-- vacio -->
              </div>

              <div class="courseProperty"><!-- Select -->
                <ui-select variant="bordered" label="¿Es base?" defaultValue="true" [(ngModel)]="localCourseData.IsBase">
                  <ui-select-content>
                    <ui-select-item value="true">Si</ui-select-item>
                    <ui-select-item value="false">No</ui-select-item>
                  </ui-select-content>
                </ui-select>
              </div>
        
              <div class="courseProperty courseProperty__75"><!-- Select -->
                <ui-select variant="bordered" label="Curso base" defaultValue="0" [(ngModel)]="localCourseData.CourseID">
                  <ui-select-content>
                    <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                    @for (courseIterated of localSchoolDataSelected?.Courses; track courseIterated.id) {
                      <ui-select-item [value]="courseIterated.id!">{{ courseIterated.NameCourse }}</ui-select-item>
                    }
                  </ui-select-content>
                </ui-select>
              </div>
            }

            @if (localCourseData_categoryID == 1) {<!-- TODO: Revisar ID para prod y para celic/cedoc -->
              <div class="courseProperty courseProperty__50"><!-- Input -->
                <ui-input
                  variant="bordered"
                  label="Registro MinEducación"
                  placeholder="Registro MinEducación"
                  [(ngModel)]="localCourseData.NoRegisterMinE"
                />
              </div>
            }

            @if (localCourseData_categoryID == 2) {<!-- TODO: Revisar ID para prod y para celic/cedoc -->
              <div class="courseProperty courseProperty__50"><!-- Input -->
                <ui-input
                  variant="bordered"
                  label="Registro Militar"
                  placeholder="Registro Militar"
                  [(ngModel)]="localCourseData.NoRegisterMiL"
                />
              </div>
            }

            <div class="courseProperty courseProperty__100"><!-- Input -->
              <ui-input
                variant="bordered"
                label="Descripción del Programa"
                placeholder="Descripción del Programa"
                [(ngModel)]="localCourseData.Description"
              />
            </div>

          </div>
        }
        @case ( MANAGE_TABS.allOptions.NOTES_CONFIG ) {
          <div class="containerCourseNotesConfigData">
            @for (item of AllInfoMidTermsCourse; track item.midtermNum; let idx = $index) {
              <div class="courseNotes">
                <div class="containerCourseNotesConfigData_inputs">
                  <input class="indexNotesSections" [(ngModel)]="item.midtermNum">
                  <div class="courseProperty courseProperty__22"><!-- Input Porcentaje -->
                    <ui-input
                      variant="bordered"
                      label="Porcentaje"
                      placeholder="Escriba Porcentaje"
                      [(ngModel)]="item.percentage"
                    />
                  </div>
                  <div class="courseProperty courseProperty__22"><!-- Select El profesor puede editar -->
                    <ui-select variant="bordered" label="El profesor puede editar" defaultValue="0" [(ngModel)]="item.professorCanEditPercentage">
                      <ui-select-content>
                        <ui-select-item value="0">No</ui-select-item>
                        <ui-select-item value="1">Si</ui-select-item>
                      </ui-select-content>
                    </ui-select>
                  </div>
                  <div class="courseProperty courseProperty__40"><!-- Input Descripción -->
                    <ui-input
                      variant="bordered"
                      label="Descripción"
                      placeholder="Escriba Descripción"
                      [(ngModel)]="item.description"
                    />
                  </div>
                </div>
                <div class="containerCourseNotesConfigData_deleteIcon">
                  <button class="invisibleBtn" (click)="confirmDeleteNotesSectionSelected(idx)">
                    <em class="icon-i-trash"></em>
                  </button>
                </div>
              </div>
            }
          </div>
          <button class="invisibleBtn" (click)="addNewNoteSection()">
            <div class="addNewNotesSection">
              <span><em class="icon-i-add"></em> Agregar</span>
            </div>
          </button>
        }
        @case ( MANAGE_TABS.allOptions.AREAS ) {
          <div class="containerCourseAreaData">
            @for (areaIterated of areaManagementToShow; track areaIterated.idArea) {
              <button (click)="areaIterated.isSelected = !areaIterated.isSelected" class="invisibleBtn btnContainerBox">
                <div class="boxCourseArea" [ngClass]="{ 'boxCourseArea_selected': areaIterated.isSelected }">
                  <div class="boxCourseArea__square"></div> <span>{{ areaIterated.name }}</span>
                </div>
              </button>
            } @empty {
              Esta escuela no tiene áreas asignadas
            }
          </div>
        }
        @case ( MANAGE_TABS.allOptions.SUBJECTS ) {
          <div class="containerCourseSubjectData">
            @for (areaIterate of areaManagementToShow; track areaIterate.idArea!) {
              @if (areaIterate.isSelected) {
                <div class="containerCourseSubjectData_areaName">
                  <span>{{ areaIterate.name }}</span>
                </div>
                @for (subjectIterated of areaIterate.allSubjectList; track subjectIterated.idSubject) {
                  <button (click)="subjectIterated.isSelected = !subjectIterated.isSelected" class="invisibleBtn btnContainerBox">
                    <div class="boxCourseSubject" [ngClass]="{ 'boxCourseSubject_selected': subjectIterated.isSelected}">
                      <div class="boxCourseSubject__square"></div> <span>{{ subjectIterated.subjectData.NameSubject }} ({{ subjectIterated.subjectData.SemesterNumber }})</span>
                    </div>
                  </button>
                }
              }
            } @empty {
              Esta escuela no tiene materias asignadas
            }
          </div>
        }
        @case ( MANAGE_TABS.allOptions.PERFORMANCES ) {
          @for (areaIterate of areaManagementToShow; track areaIterate.idArea!) {
            @if (areaIterate.isSelected) {
              @for (subjectIterated of areaIterate.allSubjectList; track subjectIterated.idSubject) {
                @if (subjectIterated.isSelected) {
                  <div class="containerCourseSubjectData_areaName">
                    <span>{{ areaIterate.name }}</span>
                  </div>
                  @if (subjectIterated.subjectData.isGradeByTask) {
                    <div class="containerCoursePerromances">
                      @for (acadPerfIterated of subjectIterated.academicPerformances; track acadPerfIterated.id; let idx = $index) {
                        <div class="containerCoursePerromances_temporalView">
                          <input class="indexNotesSections" [(ngModel)]="acadPerfIterated.performanceNum">
                          <div class="temporalView_section">
                            <ui-input
                              variant="bordered"
                              label="Descripción del desempeño"
                              placeholder="Descripción del desempeño"
                              [(ngModel)]="acadPerfIterated.descripionPerformance"
                            />
                          </div>
                          <div class="temporalView_section">
                            <ui-input
                              variant="bordered"
                              label="Porcentaje del desempeño"
                              placeholder="Porcentaje del desempeño"
                              [(ngModel)]="acadPerfIterated.percentage"
                            />
                          </div>
                          <div class="containerCourseNotesConfigData_deleteIcon">
                            <button class="invisibleBtn" (click)="confirmDeleteAcademicPerformanceSectionSelected(subjectIterated, idx)">
                              <em class="icon-i-trash"></em>
                            </button>
                          </div>
                        </div>
                      }
                    </div>
                    <!--  -->
                    <button class="invisibleBtn" (click)="addNewAcademicPerformanceSection(subjectIterated)">
                      <div class="addNewNotesSection">
                        <span><em class="icon-i-add"></em> Agregar</span>
                      </div>
                    </button>
                  } @else {
                    <span>La configuración de esta materia no permite asignar desempeños.</span>
                  }
                }
              }
            }
          }






        }
      }
    </div>
    <div class="modalFooter">
      <button (click)="confirmProcessToValidateData()">{{ dataToShow.btnText }} programa</button>
    </div>
  </div>
</div>
