import { Component, Input, OnInit } from '@angular/core';
import { CourseOffer } from '@models/db/courseOffer';
import { CourseOfferSectionDataToShow } from '@models/logicModels/mallas/dataCourseOfferSection';

@Component({
  selector: 'offer-section',
  standalone: true,
  imports: [],
  templateUrl: './offer-section.component.html',
  styleUrl: './offer-section.component.css'
})
export class OfferSectionComponent implements OnInit {
  
  @Input() courseOfferReceived!: CourseOffer;

  public courseOfferDataToShow!: CourseOfferSectionDataToShow;

  ngOnInit(): void {
    this.courseOfferDataToShow = {
      school: {
        name: this.courseOfferReceived.getSchoolAcronimAndSchoolName(),
      },
      courseOffer: {
        name: this.courseOfferReceived.NameCourseOfer!,
        type: this.courseOfferReceived.getTypeCourseOfferName(),
        quotas: `${this.courseOfferReceived.TotalPeople}`,
        registered: 'N/A', /** TODO: mostrar este dato */
        state: this.courseOfferReceived.getOferStateName(),
      }
    };
  }

}
