import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { AcademicaAreaCourse } from '@models/db/academicaAreaCourse';
import { Category } from '@models/db/category';
import { Course } from '@models/db/course';
import { CourseOffer } from '@models/db/courseOffer';
import { CourseOfferInfoMidterms } from '@models/db/CourseOfferInfoMidterms';
import { Modality } from '@models/db/Modality';
import { SubjectV2Course } from '@models/db/SubjectV2Course';
import { TypeCourse } from '@models/db/typeCourse';
import { TypeTraining } from '@models/db/TypeTraining';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'modal-manage-offer',
  standalone: true,
  imports: [
    /** Jhan components */
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    UiInputComponent,
    UiDateInputComponent,
    /** Angular */
    FormsModule,
    CommonModule,
  ],
  templateUrl: './manage-offer.component.html',
  styleUrl: './manage-offer.component.css'
})
export class ManageOfferComponent {

  @Input() isNewData!: boolean;
  @Input() offerID!: number;
  @Input() offerData!: CourseOffer;
  @Input() courseData!: Course;
  @Output() eventCloseModal = new EventEmitter<void>();
  @Output() eventUpdateDataAndCloseModal = new EventEmitter<void>();

  protected localOfferData: CourseOffer = new CourseOffer();
  protected localInfoMidTerms: CourseOfferInfoMidterms[] = [new CourseOfferInfoMidterms()];
  protected dataToShow: DataToShow = {
    tittle: '',
    subTittle: '',
    btnText: '',
  };
  protected MANAGE_TABS: ManageTabsOfferModalProperties = {
    allOptions: enumTabOptionsOfferModal,
    index: enumTabOptionsOfferModal.DETAILS,
  };
  protected areaManagementToShow: AreaManagementToShow[] = [];
  protected ALL_CATEGORIES: Category[] = [];
  protected ALL_TYPE_COURSES: TypeCourse[] = [];
  protected ALL_TYPE_TRAINING: TypeTraining[] = [];
  protected ALL_MODALITIES: Modality[] = [];

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) {
    this.ALL_CATEGORIES = this._apiService.ALL_CATEGORIES_LIST();
    this.ALL_TYPE_COURSES = this._apiService.ALL_TYPE_COURSES();
    this.ALL_TYPE_TRAINING = this._apiService.ALL_TYPE_TRAINING();
    this.ALL_MODALITIES = this._apiService.ALL_MODALITIES();
  };

  ngOnInit(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this.dataToShow = {
      tittle: ( this.isNewData ? 'Crear nueva oferta' : 'Editar oferta' ),
      subTittle: ( this.isNewData ? 'Agrega' : 'Edita' ),
      btnText: ( this.isNewData ? 'Crear' : 'Actualizar' ),
    };

    this.areaManagementToShow = [];
    this.courseData?.AcademicAreasCourse?.forEach(areaIterated => {
      let dataArea: AreaManagementToShow = {
        name: areaIterated.AcademicArea?.NameArea!,
        idArea: areaIterated.id!,
        academicAreaData: areaIterated,
        allSubjectList: [],
      };
      areaIterated.SubjectsV2?.forEach(subjectIterated => {
        let dataSubject: SubjectAreasManagementToShow = {
          isSelected: false,
          name: `${subjectIterated.nameSubject!}`,
          idSubject: subjectIterated.id!,
          subjectData: subjectIterated,
        };
        dataArea.allSubjectList.push(dataSubject);
      });

      this.areaManagementToShow.push(dataArea);
    });
    
    if (this.isNewData) {
      this.localOfferData = new CourseOffer();
      this.localOfferData.version = 2;
      this.localOfferData.SchoolID = this.courseData.SchoolID;
      this.localOfferData.CourseID = this.courseData.id;
      console.log('localOfferData', this.localOfferData);
      console.log('courseData', this.courseData);
    }
    else if (this.offerData) this.localOfferData = this.offerData;
    else {
      this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{ this.closeModal() });
      return;
    }
    this._sweetalertService.swalClose();
  }

  protected closeModal(): void {
    this.eventCloseModal.emit();
  }

  protected updateDataAndCloseModal(): void {
    this.eventUpdateDataAndCloseModal.emit();
  }

  protected validateDataBeforeSendingReq(): void {
    console.log('validateDataBeforeSendingReq');
    console.log('localOfferData: ', this.localOfferData);
    let isOk = true;
    if (this.isNewData) {
      /** TODO: hacer validaciones de campos */
      if (isOk) this.createNewOffer();
      else this._sweetalertService.swalError('Error', 'Datos incompletos', ()=>{});
    } else {
      /** TODO: hacer validaciones de campos */
      /** TODO: hacer validaciones de si hay campos editados */
      if (isOk) this.editOfferSelected();
    }
  }

  protected createNewOffer(): void {
    console.log('createNewOffer');
    const dataToCreateOffer = {
      path: `CoursesOfers`,
      data: this.localOfferData
    };
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      NEW_OFFER_00: this._apiService.post( dataToCreateOffer ).pipe( take( 1 ) ),
    }).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewOffer error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  protected editOfferSelected(): void {
    console.log('editOfferSelected');
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const dataToUpdate = {
      path: `CoursesOfers/${this.offerID}`,
      data: this.localOfferData
    };
    this._apiService.patch( dataToUpdate ).pipe( take(1) ).subscribe({
      next: (responseUpdate) => {
        console.log('responseUpdate: ', responseUpdate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han actualizado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("editAreaSelected error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  /** ************************************************************************** */
  /** ************************************************************************** */
  /** ************************************************************************** */
  protected confirmDeleteAcademicPerformanceSectionSelected(subjectIterated: CourseOfferInfoMidterms, indexNoteClicked: number): void {
    // if (subjectIterated.academicPerformances.length <= 1) return;
    // subjectIterated.academicPerformances.splice(indexNoteClicked, 1);
    // subjectIterated.academicPerformances.forEach((element, index) => {
    //   element.performanceNum = (index + 1);
    // });
  }

  // protected addNewAcademicPerformanceSection(subjectIterated: CourseOfferInfoMidterms): void {
  protected addNewAcademicPerformanceSection(): void {
    // let academicPerformanceData: AcademicPerformance = {
    //   descripionPerformance: '',
    //   performanceNum: (subjectIterated.academicPerformances.length + 1),
    //   percentage: 100,
    //   midtermNum: 1, // TODO: validar este dato con Luis
    // };
    // subjectIterated.academicPerformances.push(academicPerformanceData);
  }
  /** ************************************************************************** */
  /** ************************************************************************** */
  /** ************************************************************************** */

}

interface DataToShow {
  tittle: string,
  subTittle: string,
  btnText: string,
}

/** TODO: move to a new file */
interface ManageTabsOfferModalProperties {
  allOptions: typeof enumTabOptionsOfferModal,
  index: number,
}

/** TODO: move to a new file */
enum enumTabOptionsOfferModal {
  DETAILS,
  NOTES,
  SUBJECTS,
  PERFORMANCES,
}

interface AreaManagementToShow {
  name: string,
  idArea: number,
  academicAreaData: AcademicaAreaCourse,
  allSubjectList: SubjectAreasManagementToShow[],
}

interface SubjectAreasManagementToShow {
  isSelected: boolean,
  name: string,
  idSubject: number,
  subjectData: SubjectV2Course,
}