import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { ISchool } from '@models/school';
import { IUser } from '@models/users';
import { ApiService } from '@services/api-service.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import PAGE_ROLES from 'src/app/constants/roles';

export const msDataResolver: ResolveFn<boolean> = (route, state) => {
  const apiService = inject(ApiService);
  const router = inject(Router);

  const userId = localStorage.getItem('currentUser');
  if (!userId) {
    console.error(`No "current user" field found in local storage`);
    router.navigate(['/']);
    return false;
  }

  const userParams = {
    path: 'Userapps',
    filter: {
      where: { id: userId },
      include: [{ roleMappings: ['role', 'school'] }],
    },
  };

  return apiService.get<IUser>(userParams).pipe(
    map((users) => users[0]),
    switchMap((userInfo) => {
      const { roleMappings, ...user } = userInfo;

      const userRoles = roleMappings!.map((e) => e.role!.id!);

      const schoolParams = {
        path: 'Schools',
        filter: {
          where: { DepenSchoolID: roleMappings![0].SchoolID },
        },
      };

      return apiService.get<ISchool>(schoolParams).pipe(
        tap((schools) => {
          const childRoute = route.firstChild?.routeConfig?.path;

          PAGE_ROLES.forEach((page) => {
            if (page.path === childRoute) {
              const hasRoles = page.roles.some((role) =>
                userRoles.includes(role)
              );
              if (!hasRoles) {
                router.navigate(['/']);
                return;
              }
            }
          });

          //Set the data in the service
          apiService.userRoles.set(userRoles);
          apiService.userInfo.set(user);
          apiService.schools.set([roleMappings![0].school!, ...schools]);
        }),
        map(() => true)
      );
    }),
    catchError((error) => {
      console.error(`Error fetching user: ${error}`);
      router.navigate(['/']);
      return of(false);
    })
  );
};
