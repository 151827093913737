import { Component, Input, OnInit } from '@angular/core';
import { Course } from '@models/db/course';
import { CourseSectionDataToShow } from '@models/logicModels/mallas/dataCourseSection';

@Component({
  selector: 'course-section',
  standalone: true,
  imports: [],
  templateUrl: './course-section.component.html',
  styleUrl: './course-section.component.css'
})
export class CourseSectionComponent implements OnInit {

  @Input() courseReceived!: Course;

  public courseDataToShow!: CourseSectionDataToShow;

  constructor() { }

  ngOnInit(): void {
    this.courseDataToShow = {
      school: {
        name: this.courseReceived.getSchoolAcronimAndSchoolName(),
        url_img: this.courseReceived.getSchoolIconUrl(),
      },
      course: {
        name: this.courseReceived.NameCourse!,
        typeCourse: this.courseReceived.getTypeCourseName(),
        regMinE: this.courseReceived.getNoRegisterMinE(),
        regMil: this.courseReceived.getNoRegisterMil(),
        duration: this.courseReceived.getDuration(),
        description: this.courseReceived.getDescription(),
      }
    };
  }

}
