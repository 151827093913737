<div class="mainCOntainerOfferSectionGrades">
  <button class="containerTittle" (click)="showDetails = !showDetails">
    <span class="subjectName">NOMBRE DE LA NOTA</span>
    <div class="descriptionSection_right">
      <em class="icon-i-angle-right"></em>
    </div>
  </button>
  @if (showDetails) {
    <div class="containerDetails">
      <div class="containerDetails_row">
        <div class="rowTittle">
          <span>Duración del periodo</span>
        </div>
        <div class="rowData">
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Inicio del periodo</span><br>
            <span class="rowDataCell_info">12/12/2022</span>
          </div>
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Finalización del periodo</span><br>
            <span class="rowDataCell_info">12/12/2022</span>
          </div>
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Cantidad de notas</span><br>
            <span class="rowDataCell_info">4</span>
          </div>
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Porcentaje de nota</span><br>
            <span class="rowDataCell_info">20%</span>
          </div>
        </div>
      </div>
      <div class="containerDetails_row">
        <div class="rowTittle">
          <span>Cargue de notas</span>
        </div>
        <div class="rowData">
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Inicio cargue de notas</span><br>
            <span class="rowDataCell_info">12/12/2022</span>
          </div>
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Termino cargue de notas</span><br>
            <span class="rowDataCell_info">12/12/2022</span>
          </div>
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Estado actual: <strong>HABILITADO</strong></span><br>
            <button class="btnCallToAction">Crear fecha extraordinaria</button>
          </div>
          <div class="rowData_cell"></div>
        </div>
      </div>
      <div class="containerDetails_row">
        <div class="rowTittle">
          <span>Recuperación</span>
        </div>
        <div class="rowData">
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Inicio de recuperación</span><br>
            <span class="rowDataCell_info">12/12/2022</span>
          </div>
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Termino de recuperación</span><br>
            <span class="rowDataCell_info">12/12/2022</span>
          </div>
          <div class="rowData_cell">
            <span class="rowDataCell_tittle">Estado actual: <strong>DESHABILITADO</strong></span><br>
            <button class="btnCallToAction">Crear fecha extraordinaria</button>
          </div>
          <div class="rowData_cell"></div>
        </div>
      </div>
    </div>
  }
</div>
