import { AcademicaArea } from "./AcademicaArea";

export class Subject {
  id?: number;
  NameSubject?: string;
  CourseID?: number;
  SubjectID_Requirement?: number;
  HPM?: number; // HORAS DE TRABAJO PRESENCIAL
  HFH?: number; // HORAS DE TRABAJO CON ACOMPAÑAMIENTO
  HTI?: number; // Horas de trabajo Independiente
  Creditos?: number;
  Description?: string;
  AcademicAreaID?: number;
  SemesterNumber?: number;
  MaxSlots?: number;
  TypeRecord?: string;
  DesiredGrade?: number;
  TypeCredit?: number;
  typeCreditID?: number;
  isGradeByTask?: boolean;

  pendiente?: string; /** TODO: confirmar */

  /** ********* */
  /** RELATIONS */
  /** ********* */
  AcademicaArea?: AcademicaArea;

}
