import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { IDropdownItem } from '../models/dropdown-item';

@Injectable({
  providedIn: 'root'
})
export class UiSelectService{
  public itemList = signal<IDropdownItem[]>([]);
  public $onItemChange = new Subject<IDropdownItem>();

  //Curren clicked
  public open = signal<boolean>(false);

  public emitSelected(value:string | number) {
    const itemToAdd = this.itemList().find(e => e.value == value);
    if(itemToAdd) this.$onItemChange.next(itemToAdd);
  }

  public setVisualItem(value:string | number){
    const itemToAdd = this.itemList().find(e => e.value == value);
    return itemToAdd ? itemToAdd : null;
  }
}