import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Course } from '@models/db/course';
import { CourseSectionDataToShow } from '@models/logicModels/mallas/dataCourseSection';
import { CommonModule } from '@angular/common';
import { CourseSectionAreaComponent } from '../course-section-area/course-section-area.component';
import { CourseSectionSubjectsComponent } from '../course-section-subjects/course-section-subjects.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';

@Component({
  selector: 'course-details',
  standalone: true,
  imports: [
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    CommonModule,
    CourseSectionAreaComponent,
    CourseSectionSubjectsComponent,
  ],
  templateUrl: './course-section-details.component.html',
  styleUrl: './course-section-details.component.css'
})
export class CourseSectionDetailsComponent {

  @Input() selectedCourseData!: Course;
  @Output() responseCloseDetails = new EventEmitter<void>();
  @Output() responseShowAreaModal = new EventEmitter<{isNew: boolean, index: number}>();
  @Output() responseShowSubjectModal = new EventEmitter<{isNew: boolean, index: number}>();
  @Output() responseShowOfferModal = new EventEmitter<{isNew: boolean, index: number}>();
  @Output() responseShowCourseModal = new EventEmitter<{isNew: boolean, index: number}>();

  public courseDataToShow!: CourseSectionDataToShow;
  
  public TYPE_TAP_VIEW_ENUM = enumTypeTabViewCourseDetails;

  public viewTabIndex: number = this.TYPE_TAP_VIEW_ENUM.DETAILS;

  constructor() { }

  ngOnInit(): void {
    this.courseDataToShow = {
      school: {
        name: this.selectedCourseData.getSchoolAcronimAndSchoolName(),
        url_img: this.selectedCourseData.getSchoolIconUrl(),
      },
      course: {
        name: this.selectedCourseData.NameCourse!,
        typeCourse: this.selectedCourseData.getTypeCourseName(),
        regMinE: this.selectedCourseData.getNoRegisterMinE(),
        regMil: this.selectedCourseData.getNoRegisterMil(),
        duration: this.selectedCourseData.getDuration(),
        description: this.selectedCourseData.getDescription(),
      }
    };
  }

  closeDetails() {
    this.responseCloseDetails.emit();
  }

  showAreaModal(value: boolean, index: number) {
    this.responseShowAreaModal.emit({isNew: value, index: index});
  }

  showSubjectModal(value: boolean, index: number) {
    this.responseShowSubjectModal.emit({isNew: value, index: index});
  }

  showOfferModal() {
    this.responseShowOfferModal.emit({isNew: true, index: this.selectedCourseData.id!});
  }

  showCourseModal() {
    this.responseShowCourseModal.emit({isNew: false, index: this.selectedCourseData.id!});
  }

}

/** TODO: move to a new file */
enum enumTypeTabViewCourseDetails {
  DETAILS,
  AREAS,
  SUBJECTS,
}
