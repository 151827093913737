import { Component, Input } from '@angular/core';
import { SubjectV2Course } from '@models/db/SubjectV2Course';

@Component({
  selector: 'standard-subject-v2-course-details',
  standalone: true,
  imports: [],
  templateUrl: './standard-subject-v2-course-details.component.html',
  styleUrl: './standard-subject-v2-course-details.component.css'
})
export class StandardSubjectV2CourseDetailsComponent {

  @Input() subjectData!: SubjectV2Course;

  public showDetails: boolean = false;
  public subjectDataToShow!: SubjectStandarDataToShow;

  constructor() { }

  ngOnInit(): void {
    this.subjectDataToShow = {
      detalles: {
        name: this.subjectData.nameSubject!,
        semestre: this.subjectData.eduSup_semesterNumber!,
        tipoCalificacion: this.subjectData.typeRecord!,
        notaMinApro: 5, /** TODO: preguntar */
        areaAcademica: 'CIENCIAS NATURALES',
        prerequisito: `${this.subjectData.subjectV2ID_Requirement!}`, /** TODO: validar nombre */
        tipo: 'Créditos', /** TODO: validar */
        creditos: 5, /** TODO: validar */
        horaPresencial: 5, /** TODO: validar */
        horaAsistida: 5, /** TODO: validar */
        horaIndependiente: 5, /** TODO: validar */
        maxHoraCatDiaria: '1 hora cátedra = 45 minutos', /** TODO: validar */
        porcentajeArea: '40%', /** TODO: validar */
        descripcion: this.subjectData.description!,
      },
      desempenhos: [
        {
          nombre: 'Desempeño 1',
          porcentaje: '20%',
          actividades: 3,
          descpArea: 'THE STUDENT RECOGNIZES, CLASSIFIES AND USES NUMBERS FROM 1 TO 10, COLORS, AND BASIC VOCABULARY ABOUT FARM ANIMALS.'
        },
        {
          nombre: 'Desempeño 2',
          porcentaje: '20%',
          actividades: 3,
          descpArea: 'THE STUDENT RECOGNIZES, CLASSIFIES AND USES NUMBERS FROM 1 TO 10, COLORS, AND BASIC VOCABULARY ABOUT FARM ANIMALS.'
        },
        {
          nombre: 'Desempeño 3',
          porcentaje: '20%',
          actividades: 3,
          descpArea: 'THE STUDENT RECOGNIZES, CLASSIFIES AND USES NUMBERS FROM 1 TO 10, COLORS, AND BASIC VOCABULARY ABOUT FARM ANIMALS.'
        }
      ]
    };
  }

}

interface SubjectStandarDataToShow {
  detalles: DetailsSubjectStandarDataToShow,
  desempenhos: PerformanceSubjectStandarDataToShow[],
};

interface DetailsSubjectStandarDataToShow {
  name: string,
  semestre: number,
  tipoCalificacion: string,
  notaMinApro: number,
  areaAcademica: string,
  prerequisito: string,
  tipo: string,
  creditos: number,
  horaPresencial: number,
  horaAsistida: number,
  horaIndependiente: number,
  maxHoraCatDiaria: string,
  porcentajeArea: string,
  descripcion: string,
};

interface PerformanceSubjectStandarDataToShow {
  nombre: string,
  porcentaje: string,
  actividades: number,
  descpArea: string,
};
