import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AcademicaAreaCourse } from '@models/db/academicaAreaCourse';
import { StandardSubjectV2CourseDetailsComponent } from '../standard-subject-v2-course-details/standard-subject-v2-course-details.component';

@Component({
  selector: 'course-section-area',
  standalone: true,
  imports: [StandardSubjectV2CourseDetailsComponent],
  templateUrl: './course-section-area.component.html',
  styleUrl: './course-section-area.component.css'
})
export class CourseSectionAreaComponent {

  @Input() academicAreaData!: AcademicaAreaCourse;
  @Output() responseShowAreaModal = new EventEmitter<void>();

  public academicAreaDataToShow = {
    name: '',
    description: ''
  };

  constructor() { }

  ngOnInit(): void {
    this.academicAreaDataToShow = {
      name: this.academicAreaData.AcademicArea?.NameArea!,
      description: this.academicAreaData.AcademicArea?.Description!,
    };
  }

  showAreaModal() {
    this.responseShowAreaModal.emit();
  }

}
