import { Component, Input } from '@angular/core';
import { SubjectOffer } from '@models/db/SubjectOffer';
import { StandardSubjectOfferDetailsComponent } from '../standard-subject-offer-details/standard-subject-offer-details.component';

@Component({
  selector: 'offer-details-subjects-offer',
  standalone: true,
  imports: [ StandardSubjectOfferDetailsComponent ],
  templateUrl: './offer-details-subjects-offer.component.html',
  styleUrl: './offer-details-subjects-offer.component.css'
})
export class OfferDetailsSubjectsOfferComponent {

  @Input() subjectOfferData!: SubjectOffer;

  public subjectDataToShow = {
    name: '',
    description: ''
  };

  constructor() { }

  ngOnInit(): void {
    this.subjectDataToShow = {
      name: this.subjectOfferData.nameSubject!,
      description: this.subjectOfferData.description!,
    };
  }

}
