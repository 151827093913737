 
interface IPageRoles{
  path:string;
  roles:number[] 
} 
 
 const PAGE_ROLES:IPageRoles[] = [
  {
    path:'my-page',
    roles: [1, 3, 18],
  }
]

export default PAGE_ROLES;