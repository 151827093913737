import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable( {
  providedIn: 'root'
} )
export class SweetalertService {

  public swalLoading( title: string, message: string ) {
    Swal.fire( {
      title: title,
      text: message,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    } )
  }

  public swalSuccess( title: string, message: string, callback: Function ) {
    Swal.fire( {
      icon: 'success',
      title: title,
      text: message,
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false
    } ).then( ( result ) => {
      if ( result.isConfirmed )
        callback();
    } );
  }

  public swalError( title: string, message: string, callback: Function ) {
    Swal.fire( {
      icon: 'error',
      title: title,
      text: message,
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false
    } ).then( ( result ) => {
      if ( result.isConfirmed )
        callback();
    } );
  }

  public swalQuestion( title: string, message: string, callback: Function ) {
    Swal.fire( {
      icon: 'question',
      title: title,
      text: message,
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false
    } ).then( ( result ) => {
      if ( result.isConfirmed )
        callback();
    } );
  }

  public swalClose() {
    Swal.close();
  }

}
