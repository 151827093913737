import { Component, Input } from '@angular/core';
import { SubjectOffer } from '@models/db/SubjectOffer';

@Component({
  selector: 'standard-subject-offer-details',
  standalone: true,
  imports: [],
  templateUrl: './standard-subject-offer-details.component.html',
  styleUrl: './standard-subject-offer-details.component.css'
})
export class StandardSubjectOfferDetailsComponent {

  @Input() subjectOfferData!: SubjectOffer;

  public showDetails: boolean = false;
  public subjectDataToShow!: SubjectStandarDataToShow;

  constructor() { }

  ngOnInit(): void {
    this.subjectDataToShow = {
      detalles: {
        name: this.subjectOfferData.nameSubject!,
        semestre: this.subjectOfferData.eduSup_semesterNumber!,
        tipoCalificacion: this.subjectOfferData.typeRecord!, /** TODO: preguntar */
        notaMinApro: 5, /** TODO: preguntar */
        areaAcademica: 'CIENCIAS NATURALES',
        prerequisito: `Prerequisitos`, /** TODO: validar nombre */
        tipo: 'Créditos', /** TODO: validar */
        creditos: 5, /** TODO: validar */
        horaPresencial: 5, /** TODO: validar */
        horaAsistida: 5, /** TODO: validar */
        horaIndependiente: 5, /** TODO: validar */
        maxHoraCatDiaria: '1 hora cátedra = 45 minutos', /** TODO: validar */
        porcentajeArea: '40%', /** TODO: validar */
        descripcion: this.subjectOfferData.description!,
      },
      desempenhos: [
        {
          nombre: 'Desempeño 1',
          porcentaje: '20%',
          actividades: 3,
          descpArea: 'THE STUDENT RECOGNIZES, CLASSIFIES AND USES NUMBERS FROM 1 TO 10, COLORS, AND BASIC VOCABULARY ABOUT FARM ANIMALS.'
        },
        {
          nombre: 'Desempeño 2',
          porcentaje: '20%',
          actividades: 3,
          descpArea: 'THE STUDENT RECOGNIZES, CLASSIFIES AND USES NUMBERS FROM 1 TO 10, COLORS, AND BASIC VOCABULARY ABOUT FARM ANIMALS.'
        },
        {
          nombre: 'Desempeño 3',
          porcentaje: '20%',
          actividades: 3,
          descpArea: 'THE STUDENT RECOGNIZES, CLASSIFIES AND USES NUMBERS FROM 1 TO 10, COLORS, AND BASIC VOCABULARY ABOUT FARM ANIMALS.'
        }
      ]
    };
  }

}

interface SubjectStandarDataToShow {
  detalles: DetailsSubjectStandarDataToShow,
  desempenhos: PerformanceSubjectStandarDataToShow[],
};

interface DetailsSubjectStandarDataToShow {
  name: string,
  semestre: number,
  tipoCalificacion: string,
  notaMinApro: number,
  areaAcademica: string,
  prerequisito: string,
  tipo: string,
  creditos: number,
  horaPresencial: number,
  horaAsistida: number,
  horaIndependiente: number,
  maxHoraCatDiaria: string,
  porcentajeArea: string,
  descripcion: string,
};

interface PerformanceSubjectStandarDataToShow {
  nombre: string,
  porcentaje: string,
  actividades: number,
  descpArea: string,
};
