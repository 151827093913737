import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  computed,
  forwardRef,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { UiSelectService } from './ui-select.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { IDropdownItem } from '../models/dropdown-item';


@Component({
  selector: 'ui-select',
  templateUrl: './ui-select.component.html',
  styleUrl: './ui-select.component.css',
  standalone: true,
  imports: [CommonModule],
  providers: [
    UiSelectService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectComponent),
      multi: true,
    },
  ],
})
export class UiSelectComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  private _ref = inject(ElementRef);
  private _selectService = inject(UiSelectService);
  label = input<string>('');
  invalid = input<boolean>();
  disabled = input<boolean>();
  defaultValue = input<string | number>();
  onValueChange = output<IDropdownItem>();
  variant = input<'flat' | 'bordered' | 'underline'>('flat');
  private _isClicked = signal<boolean>(false);
  private _controlValue = signal<string | number | undefined>(undefined);
  private _destroy$: Subject<void> = new Subject();
  private _mutationObserver: MutationObserver | undefined;
  public currentItem = signal<IDropdownItem | null>(null);
  public _activeInput = computed(() => this.isTriggered() || (this.currentItem() && this.currentItem()!.value !== undefined));
  public combinedClasses = computed(() => {
    const classes = {
      'select-field--active': this._activeInput() || this._ref.nativeElement.querySelector('.decorator')?.childNodes.length > 0,
      'disabled-input': this.disabled() || this.isDisabled(),
      'hidden-label': !this.label(),
      [this.variant()]:true,
      [`invalid-field invalid-field--${this.variant()}`]: this.invalid(),
      [`disabled disabled--${this.variant()}`]: this.disabled() || this.isDisabled()
    };
    return classes;
  });

  public isTriggered = computed(() => this._selectService.open());

  //#region ACCESSORS
  private _onChange = signal<(_: any) => void>((_) => { });
  public onTouch = signal<() => void>(() => { });
  public isDisabled = signal<boolean>(false);
  //#endregion

  ngOnInit(): void {
    this._selectService.$onItemChange.pipe(takeUntil(this._destroy$))
      .subscribe((item: IDropdownItem) => {
        this._selectService.open.set(false);
        this.currentItem.set(item);
        this.onValueChange.emit({ label: item.label, value: item.value });

        //Update ngModel value
        this._onChange.update((_) => (_(item.value), _));
      });
  }

  ngAfterViewInit(): void {
    this._mutationObserver = new MutationObserver(() => this.updateItemList());
        
    // Perform initial update
    this.updateItemList();

    // Observe changes in the content list
    const node = this._ref.nativeElement.querySelector('.content-list');
    this._mutationObserver.observe(node, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  private updateItemList():void{
    const seletecItems = this._ref.nativeElement.querySelectorAll('.select-item');

    this._selectService.itemList.set(
      Array.from(seletecItems).map((element: any) => {
        const valueAttr = element.getAttribute('item-value');
        const label = element.querySelector('.select-item__content')?.textContent?.trim() || '';
        return { label, value: valueAttr };
      })
    )

    const defaultValue = this._controlValue() ?? this.defaultValue() ?? null;
    const visualItem = (defaultValue !== undefined && defaultValue !== null) 
      ? this._selectService.setVisualItem(defaultValue) 
      : null;

    this.currentItem.set(visualItem);
  }

  //#region ACCESSORS
  writeValue(value: string | number): void {
    this._controlValue.set(value);

    this.currentItem.set(
      value === '' || value === undefined || value === null 
        ? null 
        : this._selectService.setVisualItem(value)
    );
  }

  registerOnChange(fn: any): void {
    this._onChange.set(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch.set(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  //#endregion


  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    this._mutationObserver?.disconnect();
  }

  @HostListener('document:mousedown', ['$event'])
  onClickOutside(event: MouseEvent) {
    if(this.disabled() || this.isDisabled()) return;
    
    const target = event.target as HTMLElement;
    const isCurrent = this._ref.nativeElement.contains(target);
    
    this._selectService.open.set(isCurrent);

    if (isCurrent) this._isClicked.set(true);
    else if (this._isClicked() && !this.currentItem()?.value) this.onTouch()();
  }
}