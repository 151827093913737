<div class="modalMainContainer">
  <div class="myModal">
    <div class="modalHeader">
      <div class="modalHeader_info">
        <span class="headerMainInfo">{{ dataToShow.tittle }}</span><br>
        <span class="headerSecondaryInfo">Gestiona las áreas del programa existente</span><br>
      </div>
      <div class="modalHeader_close">
        <button class="btnCallToAction" (click)="closeModal()">
          <em class="icon-i-close"></em>
        </button>
      </div>
    </div>
    <div class="modalTittle">
      <div class="modalTittle_left">
        <span class="tittleMainInfo">{{ dataToShow.subTittle }}</span><br>
        <span class="tittleSecondaryInfo">Comienza a {{ dataToShow.subTittleDesc }} áreas para el programa</span><br>
      </div>
      <div class="modalTittle_right">
        <span class="tittleSecondaryInfo">Programa: <strong>{{ dataToShow.programa }}</strong></span>
      </div>
    </div>
    <div class="modalNavbar"></div>
    <div class="modalBody">
      @if (isNewData) {
        @for (areaToCreate of areaDataToCreate; track $index) {
          <div class="areaSection">
            <div class="areaSection_name">
              <span class="areaSection_label">Nombre del área</span><br>
              <input class="areaSection_info" type="text" [(ngModel)]="areaToCreate.name">
            </div>
            <div class="areaSection_descript">
              <span class="areaSection_label">Descripción del área</span><br>
              <input class="areaSection_info" type="text" [(ngModel)]="areaToCreate.descript">
            </div>
            <div class="areaSection_icons">
              <button class="invisibleBtn" (click)="confirmDeleteAreaSectionToDelete($index)">
                <em class="icon-i-trash"></em>
              </button>
            </div>
          </div>
        }
        <button class="invisibleBtn" (click)="addNewAreaSectionToCreate()">
          <div class="addNewAreaSection">
            <span><em class="icon-i-add"></em> Agregar otra área</span>
          </div>
        </button>
      } @else {
        <div class="areaSection">
          <div class="areaSection_name">
            <span class="areaSection_label">Nombre del área</span><br>
            <input class="areaSection_info" type="text" [(ngModel)]="areaDataToEdit.name">
          </div>
          <div class="areaSection_descript">
            <span class="areaSection_label">Descripción del área</span><br>
            <input class="areaSection_info" type="text" [(ngModel)]="areaDataToEdit.descript">
          </div>
          <div class="areaSection_icons">
            <button class="invisibleBtn" (click)="confirmDeleteAreaSelected()">
              <em class="icon-i-trash"></em>
            </button>
          </div>
        </div>
      }
    </div>
    <div class="modalFooter">
      <button (click)="validateDataBeforeSendingReq()">{{ dataToShow.btnText }} área</button>
    </div>
  </div>
</div>
