import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from '@models/db/Subject';
import { StandardSubjectV2CourseDetailsComponent } from '../standard-subject-v2-course-details/standard-subject-v2-course-details.component';

@Component({
  selector: 'course-section-subjects',
  standalone: true,
  imports: [StandardSubjectV2CourseDetailsComponent],
  templateUrl: './course-section-subjects.component.html',
  styleUrl: './course-section-subjects.component.css'
})
export class CourseSectionSubjectsComponent {

  @Input() subjectData!: Subject;
  @Output() responseShowSubjectModal = new EventEmitter<void>();

  public subjectDataToShow = {
    name: '',
    description: ''
  };

  constructor() { }

  ngOnInit(): void {
    this.subjectDataToShow = {
      name: this.subjectData.NameSubject!,
      description: this.subjectData.Description!,
    };
  }

  showSubjectModal() {
    this.responseShowSubjectModal.emit();
  }

}
