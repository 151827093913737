import { Component } from '@angular/core';

@Component({
  selector: 'offer-section-grades',
  standalone: true,
  imports: [],
  templateUrl: './offer-section-grades.component.html',
  styleUrl: './offer-section-grades.component.css'
})
export class OfferSectionGradesComponent {

  public showDetails: boolean = false;

}
