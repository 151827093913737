export class AcademicPerformance {
  id?: number;
  descripionPerformance?: string;
  subjectV2CourseID?: number;
  hexaColor?: string;
  performanceNum?: number;
  percentage?: number;
  midtermNum?: number;

  /** ********* */
  /** RELATIONS */
  /** ********* */

}
